import Balance from 'react-wrap-balancer';

import { cn } from '@/lib/utils';

function PageHeader({
  className = 'pb-8 flex flex-col items-center',
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <section
      className={cn(
        'flex max-w-[980px] mx-auto flex-col items-start gap-2 px-4 pt-8 md:pt-12 ',
        className,
      )}
      {...props}
    >
      {children}
    </section>
  );
}

function PageHeaderHeading({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1
      className={cn(
        'text-xl h1 mb-0 font-bold text-black dark:text-white leading-tight tracking-tighter md:text-5xl lg:leading-[1.1]',
        className,
      )}
      {...props}
    />
  );
}

function PageHeaderDescription({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <Balance
      className={cn(
        'max-w-[750px] text-lg text-muted-foreground sm:text-xl',
        className,
      )}
      {...props}
    />
  );
}

export { PageHeader, PageHeaderDescription, PageHeaderHeading };
